import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { openModal, openRC, openMS, openAnn } from "store/actions"

import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Modal,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"
import parse from "html-react-parser"

var tawklink = ""

const Alldata = JSON.parse(localStorage.getItem("dataJ"))
let agendalink = ""
const SidebarContent = props => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const { modal, onOpenModal } = props
  const { rc, onOpenRC } = props
  const { ms, onOpenMS } = props
  const { ann, onOpenAnn } = props
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const [ActiveTab, setActiveTab] = useState(Alldata.data.agenda.data.day1.name)
  const [helprc, onhelpOpenRC] = useState(false)
  const mosaicActivity = () => {
    let activityArr = localStorage.getItem("activityArr")
    if (!activityArr) {
      activityArr = []
    } else {
      activityArr = JSON.parse(activityArr)
    }
    let currentArr = activityArr.filter((elem) => elem == "Mosaic")

    // if (!currentArr.length) {
      postData(process.env.REACT_APP_ADMIN + "/submitscore", {
        gname: "Mosaic",
        id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
        score: 50,
      }).then(data => {
        // console.log(data); // JSON data parsed by `data.json()` call
        // if(data[0]=="success"){}//for successfully adding points
        activityArr.push("Mosaic")
        localStorage.setItem("activityArr", JSON.stringify(activityArr))
      })
      async function postData(url = "", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        return response.json() // parses JSON response into native JavaScript objects
      }
    // }
  }

  const toggleCustom = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [rs, setRS] = useState(false)
  const togglers = () => {
    setRS(!rs)
  }
  const [rcc, setRCC] = useState("")
  return (
    <React.Fragment>
      <Modal isOpen={rs}
        toggle={togglers}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Opening Soon!</h5>
          <button
            type="button"
            onClick={togglers}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ color: "#0b0b0b" }}>
          {rcc}
        </div>
      </Modal>

      <Modal size="xl" isOpen={modal} toggle={onOpenModal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Agenda
          </h5>
          <button
            onClick={onOpenModal}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* {props.data.areas.map((item, i) => (
                <>
                  {(() => {
                    switch (item.linktype) {
                      case "agendatypelist": {
                        <div style={{ display: "none" }}>
                          {item.url ? agendalink = item.url : agendalink = ""}
                        </div>
                      }
                    }
                  })()}
                </>
              ))} */}
          <iframe
            id={Alldata.data.lobby.data.areas[3].name}
            src={
              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/dxc%20utsav%2FAGNEDA.pdf?alt=media"
            }
            height="550px"
            width="100%"
          ></iframe>

        </div>
      </Modal>


      <Modal
        isOpen={helprc}
        toggle={() => { onhelpOpenRC(!helprc) }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Help Desk</h5>
          <button
            type="button"
            onClick={() => { onhelpOpenRC(!helprc) }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {Alldata.data.lobby.data.areas.map((item, i) => (
            (() => {
              switch (item.id) {
                case "help": {
                  <div style={{ display: "none" }}>
                    {item.url ? tawklink = item.url : tawklink = ""}
                  </div>
                }
              }
            })()
          ))}
          <iframe
            height="450px"
            width="100%"
            title="test"
            className="embed-responsive-item"
            src={encodeURI(
              tawklink
            )}
          />
        </div>
      </Modal>



      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("EASY NAVIGATION")} </li>
            <li>
              <Link to="/page/lobby" className="">
                <i className="bx bx-home-circle "></i>
                <span>{props.t("Lobby")}</span>
              </Link>
            </li>
            <li onClick={() => {onhelpOpenRC(!helprc) }}>
              <a>
                <i className="mdi mdi-help-circle-outline "></i>
                <span>{props.t("Help Desk")}</span>
              </a>
            </li>
            {/* <li>
            <Link to="/page/helpdesk" className="">
                <i className="mdi mdi-help-circle-outline "></i>
                <span>{props.t("Help Desk")}</span>
                </Link>
            </li> */}
            {Alldata.data.exhibitionarea ?
              Alldata.data.exhibitionarea.edata.restricted
                ? <li onClick={() => { setRCC(Alldata.data.exhibitionarea.edata.restrictdata.text); togglers() }}>
                  <a>
                    <i className="bx bx-book-add"></i>
                    <span>{props.t("Exhibition Hall")}</span>
                  </a>
                </li>
                : <li>
                  <Link to="/page/exhibitionarea" className="">
                    <i className="bx bx-book-add"></i>
                    <span>{props.t("Exhibition Hall")}</span>
                  </Link>
                </li>
              :
              null
            }
            {/* <li>
              <Link to="/speakers" className="">
                <i >
                {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Judges Profile")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/author" className="">
              <i className="bx bx-star"></i>
              <span>{props.t("Author's Corner")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("SESSIONS")} </li>
            <li>
              <Link to="/page/auditorium" className="">
                <i className="mdi mdi-apple-airplay "></i>
                <span className="badge rounded-pill bg-info float-end">

                </span>
                <span>{props.t("Auditorium")}</span>
              </Link>
              {/* <ul className="sub-menu" aria-expanded="false">
              <li>
              <Link to="/page/audi1" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-1")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi2" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-2")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi3" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-3")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi4" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-4")}</span>
              </Link>
            </li>
              </ul> */}
            </li>
            <li onClick={() => { onOpenModal() }}>
              <a>
                <i className="bx bx-file-blank "></i>
                <span>{props.t("Agenda")}</span>
              </a>
            </li>

            {/* <li>
              <Link to="/#" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-1")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi2" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-2")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi3" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-3")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi4" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-4")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("Engagement")} </li>
            {/* <li>
              <Link to="/grantitude" className="">
              <i className="bx bx-pencil"></i>
              <span>{props.t("GRANTitude")}</span>
              </Link>
            </li>        */}
            {/* <li>
              <Link to="/page/videolibrary" className="">
              <i className="bx bx-star"></i>
              <span>{props.t("INDUS’ Got Talent")}</span>
              </Link>
            </li>      */}
            {Alldata.data.networkingzone ?
              Alldata.data.networkingzone.edata.restricted
                ? <li onClick={() => { setRCC(Alldata.data.networkingzone.edata.restrictdata.text); togglers() }}>
                  <a>
                    <i className="bx bx-chat"></i>
                    <span>{props.t("Networking Zone")}</span>
                  </a>
                </li>
                : <li>
                  <Link to="/page/networkingzone" className="">
                    <i className="bx bx-chat"></i>
                    <span>{props.t("Networking Zone")}</span>
                  </Link>
                </li>
              :
              null
            }


            {/* <li>
              <Link to="/#" className="">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Photo Mosaic")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/pageprogram" className="">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Programs")}</span>
              </Link>
            </li> */}

            {/* <li className="menu-title">{props.t("INFORMATION")} </li> */}
            {/* <li>
              <Link to="/page/bcard" className="">
                <i className="bx bx-dock-top"></i>
                <span>{props.t("Business Cards")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/meetings" className="">
                <i className="bx bx-layout"></i>
                <span>{props.t("Meetings")}</span>
              </Link>
            </li> */}

            {/* <li onClick={onOpenRC}>
              <a>
                <i className="bx bx-file newcol"></i>
                <span>{props.t("Resource Center")}</span>
              </a>
            </li> */}

            {/* <li>
              <Link to="/page/photozone" className="">
                <i className="bx bx-camera"></i>
                <span>{props.t("Photo Zone")}</span>
              </Link>
            </li> */}
            <li>
            <a href={process.env.REACT_APP_PhotoBooth+"?email="+obj.Email+"&name="+obj.Firstname} target="_blank" className="">
              <i className="bx bx-camera"></i>
                <span>{props.t("Photo Booth")}</span>
              </a>
            </li>

            {Alldata.data.mosaic ?
              Alldata.data.mosaic.edata.restricted
                ? <li onClick={() => { setRCC(Alldata.data.mosaic.edata.restrictdata.text); togglers() }}>
                  <a>
                    <i className="bx bx-camera"></i>
                    <span>{props.t("Photo Mosaic")}</span>
                  </a>
                </li>
                :
                <li onClick={() => { onOpenMS(), mosaicActivity() }}>
                  <a>
                    <i className="bx bx-camera"></i>
                    <span>{props.t("Photo Mosaic")}</span>
                  </a>
                </li>
              :
              null
            }


            
            {Alldata.data.activityfeed ?
               <li>
                <a href={"https://" + window.location.host + "/activityfeed"} className="">
                    <i className="bx bx-hash"></i>
                    <span>{props.t("DXC Got Talent")}</span>
                  </a>
                </li>
              :
              null
            }

            {/* <li>
              <Link to="/page/socialwall" className="">
                <i className="bx bx-been-here"></i>
                <span>{props.t("Social Media Wall")}</span>
              </Link>
            </li> */}


            
            <li>
            <Link to="/page/gamezone" className="">
            <i className="bx bx-cycling"></i>
            <span>{props.t("Game Zone")}</span>
            </Link>
          </li>

          <li>
            <Link to="/page/newgames" className="">
            <i className="bx bx-joystick"></i>
            <span>{props.t("Carnival")}</span>
            </Link>
          </li> 
            


            <li className="menu-title">{props.t("INFORMATION")} </li>
            <li>
              <Link to="/leaderboard" className="">
                <i className="bx bx-trophy"></i>
                <span>{props.t("Leaderboard")}</span>
              </Link>
            </li>
            {/* <li onClick={onOpenRC}>
              <a>
                <i className="bx bx-file newcol"></i>
                <span>{props.t("STORIES OF INSPIRATION")}</span>
              </a>
            </li> */}
            <li onClick={onOpenAnn}>
              <a>
                <i className="bx bxs-bell-ring"></i>
                <span>{props.t("Announcements")}</span>
              </a>
            </li>
            

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  modal: PropTypes.any,
  rc: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
  onOpenModal: PropTypes.any,
  onOpenRC: PropTypes.any,
  onOpenAnn: PropTypes.any,
}

const mapStateToProps = ({ agenda }) => ({
  modal: agenda.modal,
  rc: agenda.rc,
  ms: agenda.ms,
  ann: agenda.ann,
})

const mapDispatchToProps = dispatch => ({
  onOpenModal: () => dispatch(openModal()),
  onOpenRC: () => dispatch(openRC()),
  onOpenMS: () => dispatch(openMS()),
  onOpenAnn: () => dispatch(openAnn()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarContent)))
