import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Badge,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "../datatables.scss"

import "./networkingZone.css"
import classnames from "classnames"
import { withRouter, Link } from "react-router-dom"
import maleAvatar from "../../assets/images/users/male-avatar.png"
import femaleAvatar from "../../assets/images/users/female-avatar.png"
import othersAvatar from "../../assets/images/users/others-avatar.png"

const Networking = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [ActiveTab, setActiveTab] = useState("one")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const loader = document.querySelector(".loader")
  const [Users, setUsers] = useState([])
  const [Meets, setMeets] = useState([])
  const [Cards, setCards] = useState([])
  const [ModalMeeting, setModalMeeting] = useState(false)
  // const [ModalMeetingData, setModalMeetingData] = useState(false)
  const [ModalCard, setModalCard] = useState(false)
  const [recvName, setrecvName] = useState("")
  const [recvEmail, setrecvEmail] = useState("")
  const [recvDesg, setrecvDesg] = useState("")
  const [recvCompany, setrecvCompany] = useState("")
  const [alertText, setalertText] = useState("")
  const [custom_div1, setcustom_div1] = useState(false)

  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  const sendBCard = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("sender", obj.Email)
    urlencoded.append("receiver", recvEmail)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_ADMIN + "/bCards/send", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.operationStatus) {
          setModalCard(false)
          setalertText(result.message)
          setcustom_div1(true)
          getAllcards()
        } else {
          alert(result.message)
        }
      })
      .catch(error => console.log("error", error))
  }

  const createMeeting = () => {
    if (
      $("#mStart").val() == "" ||
      $("#mEnd").val() == "" ||
      $("#mDate").val() == "" ||
      $("#mTitle").val() == "" ||
      $("#mlink").val() == ""
    ) {
      alert("Enter all the values!")
    } else {
      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

      var urlencoded = new URLSearchParams()
      urlencoded.append("organiser", obj.Email)
      urlencoded.append("attendee", recvEmail)
      urlencoded.append("startTime", $("#mStart").val())
      urlencoded.append("endTime", $("#mEnd").val())
      urlencoded.append("date", $("#mDate").val())
      urlencoded.append("title", $("#mTitle").val())
      urlencoded.append("link", $("#mlink").val())

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      }

      fetch(process.env.REACT_APP_ADMIN + "/meetings/create", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.operationStatus) {
            $("#mStart").val("")
            $("#mEnd").val("")
            $("#mDate").val("")
            $("#mTitle").val("")
            $("#mlink").val("")
            setModalMeeting(false)
            setalertText("Meeting Created!")
            setcustom_div1(true)
            getAllMeetings()
          } else {
            alert(result.message)
          }
        })
        .catch(error => console.log("error", error))
    }
  }

  const changeStatus = (id, action) => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("id", id)
    if (action == "Accept") {
      urlencoded.append("attendeeStatus", "Meeting Confirmed")
      urlencoded.append("organiserStatus", "Meeting Confirmed")
    } else {
      urlencoded.append("attendeeStatus", "Meeting Cancelled")
      urlencoded.append("organiserStatus", "Meeting Cancelled")
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_ADMIN + "/meetings/changeStatus",
      requestOptions
    )
      .then(response => response.json())
      .then(async result => {
        if (result.operationStatus) {
          if (action == "Accept") {
            setalertText("Meeting Accepted")
          } else {
            setalertText("Meeting Cancelled")
          }
          setcustom_div1(true)
          getAllMeetings()
        } else {
          alert(result.message)
        }
      })
      .catch(error => console.log("error", error))
  }

  const getAllMeetings = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("Email", obj.Email)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_ADMIN + "/meetings/getAllMeetings",
      requestOptions
    )
      .then(response => response.json())
      .then(async result => {
        let newData = await result.map(function (objj, Index) {
          objj.sno = Index + 1
          if (objj.organiser == obj.Email) {
            objj.status = objj.organiserStatus
          } else {
            objj.status = objj.attendeeStatus
          }
          return objj
        })
        setMeets(newData)
      })
      .catch(error => console.log("error", error))
  }

  const getAllcards = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("Email", obj.Email)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_ADMIN + "/bCards/getCards", requestOptions)
      .then(response => response.json())
      .then(async result => {
        let newData = await result.map(function (objj, Index) {
          objj.sno = Index + 1
          if (objj.state) {
            objj.status = "Cards Exchanged"
            if (objj.user1 == obj.Email) {
              objj.email = objj.user2
              objj.name =
                objj.user2Array[0].Firstname +
                " " +
                (objj.user2Array[0].Lastname
                  ? objj.user2Array[0].Lastname
                  : null)
              objj.Company = objj.user2Array[0].Company
              objj.JobTitle = objj.user2Array[0].JobTitle
              objj.phonenumber = objj.user2Array[0].PhoneNumber
            } else {
              objj.email = objj.user1
              objj.name =
                objj.user1Array[0].Firstname +
                " " +
                (objj.user1Array[0].Lastname
                  ? objj.user1Array[0].Lastname
                  : null)
              objj.Company = objj.user1Array[0].Company
              objj.JobTitle = objj.user1Array[0].JobTitle
              objj.phonenumber = objj.user1Array[0].PhoneNumber
            }
          } else {
            if (objj.user1 == obj.Email) {
              objj.status = "Card Sent"
              // objj.email = objj.user2
              objj.name =
                objj.user2Array[0].Firstname +
                " " +
                (objj.user2Array[0].Lastname
                  ? objj.user2Array[0].Lastname
                  : null)
              objj.Company = objj.user2Array[0].Company
              objj.JobTitle = objj.user2Array[0].JobTitle
            } else {
              objj.status = "Card Received"
              objj.email = objj.user1
              objj.name =
                objj.user1Array[0].Firstname +
                " " +
                (objj.user1Array[0].Lastname
                  ? objj.user1Array[0].Lastname
                  : null)
              objj.Company = objj.user1Array[0].Company
              objj.JobTitle = objj.user1Array[0].JobTitle
              objj.phonenumber = objj.user1Array[0].PhoneNumber
            }
          }
          return objj
        })
        setCards(newData)
        console.log("cards data", newData)
      })
      .catch(error => console.log("error", error))
  }

  useEffect(() => {
    //meeting 3
    // getAllMeetings()
    getAllcards()
    loader.style.display = "none"

    fetch(process.env.REACT_APP_ADMIN + "/getuserlist")
      .then(response => response.json())
      .then(async data => {
        let newData = await data.map(function (obj, Index) {
          obj.sno = Index + 1
          return obj
        })
        setUsers(newData)
      })

    setTimeout(() => {
      if (true) {
        CometChatWidget.init({
          appID: "208110919cfd1f73",
          appRegion: "us",
          authKey: "6664e1d4a16e6922c424f5cf3a4a6251206467bd",
        }).then(
          response => {
            /**
             * Create user once initialization is successful
             */
            // const obj1 = JSON.parse(localStorage.getItem("authUser"))
            const UID = obj._id
            const USERNAME =
              obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
            CometChatWidget.createOrUpdateUser({
              uid: UID,
              name: USERNAME,
            }).then(user => {
              console.log("Initialization completed successfully")
              //You can now call login function.
              CometChatWidget.login({
                uid: UID,
              }).then(
                response => {
                  CometChatWidget.launch({
                    widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
                    docked: "true",
                    alignment: "right", //left or right
                    roundedCorners: "true",
                    height: "calc(100% - 180px)",
                    width: "400px",
                    defaultID: "", //default UID (user) or GUID (group) to show,
                    defaultType: "user", //user or group
                  })
                  // if (props.edata.chatroomOpen) {
                  //   setTimeout(() => { CometChatWidget.openOrCloseChat(true) }, 1000)
                  // }
                },
                error => {
                  console.log("User login failed with error:", error)
                  //Check the reason for error and take appropriate action.
                }
              )
            })
          },
          error => {
            console.log("Initialization failed with error:", error)
            //Check the reason for error and take appropriate action.
          }
        )
      } else {
        if (document.getElementById("cometchat__widget")) {
          document.getElementById("cometchat__widget").style.display = "none"
        }
      }
    }, 1000)
  }, [])

  const cardButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        style={{ backgroundColor: "#2C78A0", borderColor: "#2C78A0" }}
        onClick={() => {
          // console.log(row.Email);
          setModalCard(!ModalCard)
          setrecvEmail(row.Email)
          setrecvName(row.Firstname)
          setrecvDesg(row.Designation)
          setrecvCompany(row.Company)
        }}
      >
        Exchange Business Card
      </Button>
    )
  }
  const meetingButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        style={{ backgroundColor: "#2C78A0", borderColor: "#2C78A0" }}
        onClick={() => {
          setModalMeeting(!ModalMeeting)
          setrecvEmail(row.Email)
          setrecvName(row.Firstname)
          setrecvDesg(row.Designation)
          setrecvCompany(row.Company)
        }}
      >
        Schedule Meeting
      </Button>
    )
  }
  const chatButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        style={{ backgroundColor: "#2C78A0", borderColor: "#2C78A0" }}
        onClick={() => {
          // setmoda(!moda)
          // console.log(row._id)
          CometChatWidget.chatWithUser(row._id)
          CometChatWidget.openOrCloseChat(true)
        }}
      >
        Chat
      </Button>
    )
  }

  const columnsNetworking = [
    {
      dataField: "img",
      text: "#",
      formatter: (cellContent, user) => (
        <>
          {!user.img ? (
            user.SelectedAvatar ? (
              <div className="avatar-xs">
              <img
                className="rounded-circle avatar-xs"
                src={(user?.SelectedAvatar==="maleAvatar" && maleAvatar) ||
                (user?.SelectedAvatar==="femaleAvatar" && femaleAvatar) ||
                (user?.SelectedAvatar==="othersAvatar" && othersAvatar)
                }
                alt=""
              />
            </div>
            ):(
              <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {user.Firstname.charAt(0)}
              </span>
            </div>
            )
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={images[user.img]}
                alt=""
              />
            </div>
          )}
        </>
      ),
      // User pics but too heavy
      // formatter: (cellContent, user) => (
      //   <>
      //     {user.userProfile.length ? console.log("prof",user.userProfile[0].Profile): null}
      //     {!user.userProfile.length ? (
      //       <div className="avatar-xs">
      //         <span className="avatar-title rounded-circle">
      //           {user.Firstname.charAt(0)}
      //         </span>
      //       </div>
      //     ) : (
      //       <div>
      //         <img
      //           className="rounded-circle avatar-xs"
      //           src={user.userProfile[0].Profile}
      //           alt=""
      //         />
      //       </div>
      //     )}
      //   </>
      // ),
    },
    {
      text: "Name",
      dataField: "Firstname",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.Firstname + " " + (user.Lastname ? user.Lastname : "")}
            </Link>
          </h5>
          <p className="text-muted mb-0">
            {user.JobTitle}
            <br />
            {user.Company}
          </p>
        </>
      ),
      filterValue: (cell, row) => row.Firstname + " " + row.Lastname,
    },
    {
      dataField: "Email",
      text: "",
      formatter: cardButton,
      sort: false,
    },
    {
      dataField: "JobTitle",
      text: "JobTitle",
      hidden: true,
      sort: false,
    },
    {
      dataField: "Company",
      text: "Company",
      hidden: true,
      sort: false,
    },
    //meeting 5
    // {
    //   dataField: "Email",
    //   text: "",
    //   formatter: meetingButton,
    //   sort: false,
    // },
    {
      dataField: "Email",
      text: "",
      formatter: chatButton,
      sort: false,
    },
  ]

  const columnsMeeting = [
    {
      dataField: "img",
      text: "#",
      formatter: (cellContent, user) => (
        <>
          {!user.img ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {user.organiser.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={images[user.img]}
                alt=""
              />
            </div>
          )}
        </>
      ),
    },
    {
      text: "Email",
      dataField: "attendee",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.attendee}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{user}</p> */}
        </>
      ),
    },
    {
      dataField: "title",
      text: "Meeting Title",
      sort: false,
    },
    {
      dataField: "date",
      text: "Date & Time",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          <p className="text-muted mb-0">{user.date}</p>
          <p className="text-muted mb-0">{user.startTime}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.status == "Meeting Confirmed" && (
            <Badge className="me-2 bg-success">{user.status}</Badge>
          )}
          {user.status == "Request Received" && (
            <Badge className="me-2 bg-info">{user.status}</Badge>
          )}
          {user.status == "Request Pending" && (
            <Badge className="me-2 bg-warning">{user.status}</Badge>
          )}
          {user.status == "Meeting Cancelled" && (
            <Badge className="me-2 bg-danger">{user.status}</Badge>
          )}
        </>
      ),
    },
    {
      dataField: "",
      text: "Action",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.status == "Meeting Confirmed" && (
            <>
              <Button
                onClick={() => {
                  window.open(user.link, "_blank").focus()
                }}
                color="success"
                className="btn btn-success mb-1"
              >
                Join
              </Button>{" "}
              {/* <br />{" "} */}
              <Button
                onClick={() => {}}
                color="info"
                className="btn btn-info mb-1"
              >
                Add to Calender
              </Button>{" "}
              {/* <br />{" "} */}
              <Button
                onClick={() => {
                  changeStatus(user._id, "Cancel")
                }}
                color="danger"
                className="btn btn-danger mb-1"
              >
                Cancel
              </Button>{" "}
              {/* <br />{" "} */}
            </>
          )}
          {user.status == "Request Received" && (
            <>
              <Button
                onClick={() => {
                  changeStatus(user._id, "Accept")
                }}
                color="primary"
                className="btn btn-primary mb-1"
              >
                Accept
              </Button>{" "}
              {/* <br />{" "} */}
              <Button
                onClick={() => {}}
                color="info"
                className="btn btn-info mb-1"
              >
                Add to Calender
              </Button>{" "}
              {/* <br />{" "} */}
              <Button
                onClick={() => {
                  changeStatus(user._id, "Cancel")
                }}
                color="danger"
                className="btn btn-danger mb-1"
              >
                Cancel
              </Button>{" "}
              {/* <br />{" "} */}
            </>
          )}
          {user.status == "Request Pending" && (
            <>
              <Button
                onClick={() => {}}
                color="info"
                className="btn btn-info mb-1"
              >
                Add to Calender
              </Button>{" "}
              {/* <br />{" "} */}
              <Button
                onClick={() => {
                  changeStatus(user._id, "Cancel")
                }}
                color="danger"
                className="btn btn-danger mb-1"
              >
                Cancel
              </Button>{" "}
              {/* <br />{" "} */}
            </>
          )}
        </>
      ),
    },
  ]

  const columnsCards = [
    {
      dataField: "img",
      text: "#",
      formatter: (cellContent, user) => (
        <>
          {!user.img ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {user.name.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={images[user.img]}
                alt=""
              />
            </div>
          )}
        </>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {console.log("card details", user)}
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.name}
            </Link>
          </h5>
          <p className="text-muted mb-0">
            {user.JobTitle}
            <br />
            {user.Company}
          </p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "phonenumber",
      text: "Phone Number",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.status == "Cards Exchanged" && (
            <Badge className="me-2 bg-success">{user.status}</Badge>
          )}
          {user.status == "Card Sent" && (
            <Badge className="me-2 bg-info">{user.status}</Badge>
          )}
          {user.status == "Card Received" && (
            <Badge className="me-2 bg-warning">{user.status}</Badge>
          )}
        </>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "total",
      order: "desc",
    },
  ]

  const pageOptionsNetworking = {
    sizePerPage: 12,
    totalSize: Users.length, // replace later with size(customers),
    custom: true,
  }

  const pageOptionsCards = {
    sizePerPage: 12,
    totalSize: Cards.length, // replace later with size(customers),
    custom: true,
  }

  const pageOptionMeeting = {
    sizePerPage: 12,
    totalSize: Meets.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "12", value: 12 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: 50 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      {custom_div1 ? (
        <SweetAlert
          title={alertText}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={() => {
            setcustom_div1(false)
          }}
        ></SweetAlert>
      ) : null}
      <Modal
        size="md"
        isOpen={ModalCard}
        toggle={() => {
          setModalCard(!ModalCard)
        }}
      >
        <ModalHeader
          toggle={() => {
            setModalCard(!ModalCard)
          }}
        >
          Connect with {recvName}
        </ModalHeader>
        <ModalBody>
          <CardText className="theme">
            {" "}
            Your information will be shared with {recvName} <br />
            Are you sure you want to proceed?{" "}
          </CardText>
          <Card className="theme">
            <CardTitle className="mt-0">
              {" "}
              <center>
                {" "}
                {obj.Firstname + " " + (obj.Lastname ? obj.Lastname : "")}{" "}
              </center>
            </CardTitle>
            {/* <CardImg
              top
              className="img-fluid"
              style={{
                maxHeight: "100px",
                objectFit: "contain",
                objectPosition: "top",
              }}
              src={
                "http://localhost:3000/static/media/user.d1a7045e.d1a7045e.png"
              }
              alt="Skote"
            /> */}
            <CardTitle>
              <center>
                <div className="avatar-sm">
                  <span className="avatar-title rounded-circle">
                    {obj.Firstname.charAt(0)}
                  </span>
                </div>
              </center>
            </CardTitle>
            <CardBody>
              <ul className="list-group list-group-flush">
                <li className="list-group-item theme">
                  <center>
                    <CardTitle className="mt-0">{obj.Email}</CardTitle>
                  </center>
                </li>
                <li className="list-group-item theme">
                  <center>
                    <CardTitle className="mt-0">
                      {obj.JobTitle ? obj.JobTitle : null}
                    </CardTitle>
                  </center>
                </li>
                <li className="list-group-item theme">
                  <center>
                    <CardTitle className="mt-0">
                      {obj.Company ? obj.Company : null}
                    </CardTitle>
                  </center>
                </li>
                <li className="list-group-item theme">
                  <center>
                    <CardTitle className="mt-0">
                      {obj.PhoneNumber ? obj.PhoneNumber : null}
                    </CardTitle>
                  </center>
                </li>
              </ul>
              <div>
                <center>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                      window.location.href = "/profile"
                    }}
                  >
                    Edit
                  </button>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <button
                    onClick={() => {
                      sendBCard()
                    }}
                    className="btn btn-primary mt-2"
                  >
                    Confirm
                  </button>
                </center>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={ModalMeeting}
        toggle={() => {
          setModalMeeting(!ModalMeeting)
        }}
      >
        <ModalHeader
          toggle={() => {
            setModalMeeting(!ModalMeeting)
          }}
        >
          Connect with {recvName}
        </ModalHeader>
        <ModalBody>
          <Card style={{ backgroundColor: "transparent" }}>
            <CardBody style={{ padding: 0 }}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <center>
                    <input
                      className="form-control"
                      id="mTitle"
                      type="text"
                      placeholder="Meeting Title"
                    ></input>
                  </center>
                </li>
                <li className="list-group-item">
                  <label className="col-form-label">Start Time</label>
                  <center>
                    <input
                      className="form-control"
                      id="mStart"
                      type="time"
                      placeholder="Meeting start time"
                    ></input>{" "}
                  </center>
                  <label className="col-form-label">Start Time</label>
                  <center>
                    <input
                      className="form-control"
                      id="mEnd"
                      type="time"
                      placeholder="Meeting end time"
                    ></input>
                  </center>
                </li>
                {/* <li className="list-group-item">
                  <center>
                    
                  </center>
                </li> */}
                <li className="list-group-item">
                  <label className="col-form-label">Date</label>
                  <center>
                    <input
                      className="form-control"
                      id="mDate"
                      type="date"
                      placeholder="Meeting date"
                    ></input>
                  </center>
                </li>
                <li className="list-group-item">
                  <center>
                    <input
                      className="form-control"
                      id="mlink"
                      type="url"
                      placeholder="Meeting Link"
                    ></input>
                  </center>
                </li>
              </ul>
              <div>
                <center>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      createMeeting()
                    }}
                  >
                    Confirm
                  </button>
                </center>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "one",
                })}
                onClick={() => {
                  toggle1("one")
                  setactiveTabContent("1")
                }}
              >
                Networking
              </NavLink>
            </NavItem>
            {/* meeting 7
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "two",
                })}
                onClick={() => {
                  toggle1("two")
                  setactiveTabContent("2")
                }}
              >
                Meetings Scheduled
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "three",
                })}
                onClick={() => {
                  toggle1("three")
                  setactiveTabContent("3")
                }}
              >
                Business Cards Exchanged
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTabContent} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h1"
                        style={{ color: "#000" }}
                      ></CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptionsNetworking)}
                        keyField="key"
                        columns={columnsNetworking}
                        data={Users}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columnsNetworking}
                            data={Users}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          placeholder="Search for Name, Designation or Company "
                                          style={{ minWidth: "24rem" }}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            {/* meeting 8
            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h1"
                        style={{ color: "#000" }}
                      ></CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptionsMeeting)}
                        keyField="key"
                        columns={columnsMeeting}
                        data={Meets}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columnsMeeting}
                            data={Meets}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane> */}

            <TabPane tabId="3">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h1"
                        style={{ color: "#000" }}
                      ></CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptionsCards)}
                        keyField="key"
                        columns={columnsCards}
                        data={Cards}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columnsCards}
                            data={Cards}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Networking
