import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardText,
  CardHeader,
  CardBody,
  CardTitle,
  CardImg,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import "./ActFeed.css"
import { parseInt } from "lodash"
import ActivityFeedComment from "./ActivityFeedComment"

const ActivityFeedCard = props => {
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [likedisable, setlikeDisable] = useState(false)
  const [likeCount, setlikeCount] = useState(props.cardLikeCount)
  const [unlikedisable, setunlikeDisable] = useState(false)
  const [commentsView, setCommentsView] = useState("")
  const [comment, setComment] = useState("")

  const callComentApi = (Userposturl) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }
    if (comment) {
      postData(process.env.REACT_APP_ADMIN + "/userpost/addComment", {
        Firstname:obj.Firstname ,Email:obj.Email,Userposturl,UserComment:comment
      }).then(data => {
        console.log(data); // JSON data parsed by `data.json()` call
        // setObj(data)
        // localStorage.setItem("authUser", JSON.stringify(data))
        callActivityApi("Comment",20)
      })
     } else {
       if (!selectedFiles) {
         alert("Type comment first")
       }
      }
  }

  const callActivityApi = (gname,points) => {
    let activityArr =  localStorage.getItem("activityArr")
    if(!activityArr){
      activityArr=[]
    }else{
      activityArr = JSON.parse(activityArr)
    }
    let currentPageArr = activityArr.filter((elem)=>elem==gname)

    // if(!currentPageArr.length){
      postData(process.env.REACT_APP_ADMIN + "/submitscore", {
        gname: gname,
        id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
        score: points,
      }).then(data => {
        // console.log(data); // JSON data parsed by `data.json()` call
        // if(data[0]=="success"){}//for successfully adding points
        activityArr.push(gname)
        localStorage.setItem("activityArr", JSON.stringify(activityArr))
        window.location.reload()//to be made dynamic

      })
    // }
  
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }
  }

  const callApi = (type, Userposturl) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    postData(process.env.REACT_APP_ADMIN + "/userpost/" + type, {
      Userposturl,
      Email: obj.Email,
    }).then(data => {
      // console.log(data); // JSON data parsed by `data.json()` call
      setObj(data)
      localStorage.setItem("authUser", JSON.stringify(data))
      if(type=="addUserPostLike")
        callActivityApi("Like",10)
    })
  }
  return (
    <>
      {/* <Card className="p-1 border shadow-none"> */}
      {/* <div className="p-3">
                <h5>
                    <div to="blog-details" className="text-dark">
                        {props.cardUsername}
                    </div>
                </h5>
                <p className="text-muted mb-0">{props.cardCaption}</p>
            </div>

            <div className="position-relative">
            <center>
                {console.log(props.cardImage)}
                {(props.cardType=="image")
                    ?

                    <img
                        style={{ maxHeight: "50vh", height: "50vh" }}
                        src={props.cardUrl}
                        alt=""
                        className=""
                    />
                    :
                    <iframe
                        style={{ maxHeight: "50vh", height: "50vh",maxWidth:"45vw",width:"45vw" }}
                        title="Post Image/ Video"
                        className="embed-responsive-item"
                        src={props.cardUrl}
                    />
                }

                </center>
            </div> */}
      <Card className="activityCard p-4">
        <Row className="no-gutters">
          <Col md={6}>
            <CardBody>
              <CardTitle>{props.cardUsername}</CardTitle>
              <CardText>{props.cardCaption}</CardText>
              <CardText>
                <span
                  className="p-3"
                  // style={{position:"absolute",bottom:"0px"}}
                  id=" likeid"
                >
                  <ul className="list-inline" style={{margin:"0px"}}>
                    <li className="list-inline-item me-3">
                      {/* <Link to="#" className="text-muted"> */}
                      {/* <i className="bx bxs-heart align-middle text-muted me-1 increased-heartsize" ></i>{" "} */}
                      {obj.Userlikedpost &&
                      obj.Userlikedpost.includes(props.cardUrl) ? (
                        <span>
                          <button
                            type="button"
                            disabled={likedisable}
                            className="btntrans"
                            onClick={() => {
                              setlikeDisable(true)
                              setunlikeDisable(false)
                              setlikeCount(parseInt(likeCount) - 1)
                              callApi("disLikeUserPost", props.cardUrl)
                            }}
                          >
                            <i
                              className="bx bxs-heart align-middle  heartColor increased-heartsize"
                              style={{ color: "red" }}
                            ></i>
                          </button>
                          {likeCount}
                          {/* <div> You & {props.cardLikeCount} others</div> */}
                        </span>
                      ) : (
                        <span>
                          <button
                            type="button"
                            disabled={unlikedisable}
                            className="btntrans"
                            onClick={() => {
                              setunlikeDisable(true)
                              setlikeDisable(false)
                              setlikeCount(parseInt(likeCount) + 1)
                              callApi("addUserPostLike", props.cardUrl)
                            }}
                          >
                            <i
                              className="bx bx-heart align-middle  heartColor increased-heartsize"
                              style={{ color: "red" }}
                            ></i>
                          </button>
                          {likeCount}
                          {/* <div> {props.cardLikeCount}</div> */}
                        </span>
                      )}
                      <button type="button" className="btntrans" onClick={()=>{
                        setCommentsView(!commentsView)
                      }} >
                        <i
                          className="bx bx-comment-dots align-middle text-muted me-1 increased-heartsize"
                        ></i>
                        {props.cardComments.length}
                      </button>
                      {/* </Link> */}
                    </li>
                    {/* <li className="list-inline-item me-3">
                        <Link to="#" className="text-muted">
                            <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                            12 Comments
                        </Link>
                    </li> */}
                  </ul>
                  {/* <p>
                    Neque porro quisquam est, qui dolorem ipsum quia
                    dolor sit amet
                </p>

                <div>
                    <Link to="#" className="text-primary">
                        Read more{" "}
                        <i className="mdi mdi-arrow-right"></i>
                    </Link>
                </div> */}
                </span>
              </CardText>
              {commentsView && <div className="commentDiv" id="style-1">
              {commentsView && props.cardComments.map((comment, index) => {
                return <ActivityFeedComment Firstname={comment.Firstname} UserComment={comment.UserComment} />
              })}
              </div>}
              {/* <Form> */}
                <span className="mb-4 row">
                  <Col lg="9">
                    <input
                      id="ActCommentInput"
                      name="Comment"
                      type="textarea"
                      className="form-control"
                      placeholder="Type Your comments here..."
                      style={{
                        height:"125%"
                      }}
                      onChange={e => {
                        setComment(e.target.value)
                      }}
                    />
                  </Col>
                  <Col lg="2" >
                  <button
                className="btn btn-feed mt-1"
                  onClick={() => {
                    callComentApi(props.cardUrl)
                  }}
                >
                  Submit
                </button>
                    
                  </Col>
                  
                </span>
                
              {/* </Form> */}
            </CardBody>
          </Col>
          <Col md={6}>
            {props.cardType == "image" ? (
              <CardImg
                className=""
                src={props.cardUrl}
                alt="User Post Image"
                style={{ maxHeight: "400px" }}
              />
            ) : (
              <video
                controls
                title="Post Image/ Video"
                className="embed-responsive-item activityVideo"
                src={props.cardUrl}
              >
                <source src={props.cardUrl} type="video/mp4" />
              </video>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

ActivityFeedCard.propTypes = {
  cardUsername: PropTypes.string,
  cardCaption: PropTypes.string,
  cardType: PropTypes.string,
  cardLikeCount: PropTypes.string,
  cardUrl: PropTypes.string,
  cardText: PropTypes.string,
  cardComments: PropTypes.array,
}

export default ActivityFeedCard
