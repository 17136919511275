import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"

import "./datatables.scss"

import "./lb.css"
import classnames from "classnames"

const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Leaderboard = () => {
  const { ExportCSVButton } = CSVExport
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [ActiveTab, setActiveTab] = useState("Individual")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const loader = document.querySelector(".loader")
  const [IndividualScore, setIndividualScore] = useState([])
  const [TeamScore, setTeamScore] = useState([])

  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (false) {
        CometChatWidget.init({
          appID: "208110919cfd1f73",
          appRegion: "us",
          authKey: "6664e1d4a16e6922c424f5cf3a4a6251206467bd",
        }).then(
          response => {
            /**
             * Create user once initialization is successful
             */
            // const obj1 = JSON.parse(localStorage.getItem("authUser"))
            const UID = obj._id
            const USERNAME =
              obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
            CometChatWidget.createOrUpdateUser({
              uid: UID,
              name: USERNAME,
            }).then(user => {
              console.log("Initialization completed successfully")
              //You can now call login function.
              CometChatWidget.login({
                uid: UID,
              }).then(
                response => {
                  CometChatWidget.launch({
                    widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
                    docked: "true",
                    alignment: "right", //left or right
                    roundedCorners: "true",
                    height: "calc(100% - 180px)",
                    width: "400px",
                    defaultID: "", //default UID (user) or GUID (group) to show,
                    defaultType: "user", //user or group
                  })
                  // if (props.edata.chatroomOpen) {
                  if (false) {
                    setTimeout(() => {
                      CometChatWidget.openOrCloseChat(true)
                    }, 1000)
                  }
                },
                error => {
                  console.log("User login failed with error:", error)
                  //Check the reason for error and take appropriate action.
                }
              )
            })
          },
          error => {
            console.log("Initialization failed with error:", error)
            //Check the reason for error and take appropriate action.
          }
        )
      } else {
        if (document.getElementById("cometchat__widget")) {
          document.getElementById("cometchat__widget").style.display = "none"
        }
      }
    }, 1000)
    loader.classList.add("loader--hide")
    let scorr = []
    let Teamscorr = []
    // fetch(process.env.REACT_APP_ADMIN + "/getteamscore")
    //   .then(response1 => response1.json())
    //   .then(async teamdata => {
    //     await teamdata.map((Teamel, Teamindex) => {
    //       let TeamtotalScore = Teamel.scores.reduce(function (
    //         accumulator1,
    //         sc1
    //       ) {
    //         return accumulator1 + sc1.score
    //       },
    //       0)
    //       let Teamitem = {
    //         key: Teamindex,
    //         id: Teamel._id,
    //         name: Teamel.Teamname,
    //         createdAt: Teamel.createdAt,
    //         total: TeamtotalScore,
    //         rank: 0,
    //         // "Knife Ninja": Teamel.scores.filter(Teamel => { return Teamel.gname == "Knife Ninja" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Knife Ninja" })[0].score : 0,
    //         // "Monsters Up": Teamel.scores.filter(Teamel => { return Teamel.gname == "Monsters Up" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Monsters Up" })[0].score : 0,
    //         // "Mr.Gun": Teamel.scores.filter(Teamel => { return Teamel.gname == "Mr.Gun" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Mr.Gun" })[0].score : 0,
    //         // "Flappy Dove": Teamel.scores.filter(Teamel => { return Teamel.gname == "Flappy Dove" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Flappy Dove" })[0].score : 0,
    //         // "TEN": Teamel.scores.filter(Teamel => { return Teamel.gname == "TEN" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "TEN" })[0].score : 0,
    //         // "Quiz": Teamel.scores.filter(Teamel => { return Teamel.gname == "Quiz" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Quiz" })[0].score : 0,
    //         // "Fortune WheTeamel": Teamel.scores.filter(Teamel => { return Teamel.gname == "Fortune WheTeamel" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Fortune WheTeamel" })[0].score : 0,
    //         // "Cricket Challenge": Teamel.scores.filter(Teamel => { return Teamel.gname == "Cricket Challenge" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Cricket Challenge" })[0].score : 0,
    //         // "Easter Memory": Teamel.scores.filter(Teamel => { return Teamel.gname == "Easter Memory" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Easter Memory" })[0].score : 0,
    //         // "Car Rush": Teamel.scores.filter(Teamel => { return Teamel.gname == "Car Rush" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Car Rush" })[0].score : 0,
    //         // "Jigsaw": Teamel.scores.filter(Teamel => { return Teamel.gname == "Jigsaw" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Jigsaw" })[0].score : 0,
    //         // "Pinball": Teamel.scores.filter(Teamel => { return Teamel.gname == "Pinball" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Pinball" })[0].score : 0,
    //         // "Penalty Kicks": Teamel.scores.filter(Teamel => { return Teamel.gname == "Penalty Kicks" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Penalty Kicks" })[0].score : 0,
    //         // "City Blocks": Teamel.scores.filter(Teamel => { return Teamel.gname == "City Blocks" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "City Blocks" })[0].score : 0,
    //       }
    //       Alldata.helper.leaderboard.active.map((active, ind) => {
    //         Teamitem[active] = Teamel.scores.filter(Teamel => {
    //           return Teamel.gname == Teamitem.active
    //         })
    //           ? Teamel.scores.filter(Teamel => {
    //               return Teamel.gname == active
    //             })[0]
    //             ? Teamel.scores.filter(Teamel => {
    //                 return Teamel.gname == active
    //               })[0].score
    //             : 0
    //           : 0
    //       })
    //       Teamscorr.push(Teamitem)
    //     })
    //     // sort by value
    //     Teamscorr.sort(function (c, d) {
    //       return d.total - c.total
    //     })
    //     let i = 1
    //     Teamscorr[i - 1].rank = 1
    //     // console.log(Teamscorr[i-1].total);
    //     while (i < Teamscorr.length) {
    //       if (Teamscorr[i - 1].total == Teamscorr[i].total) {
    //         Teamscorr[i].rank = Teamscorr[i - 1].rank
    //       } else {
    //         Teamscorr[i].rank = Teamscorr[i - 1].rank + 1
    //       }
    //       i++
    //     }
    //     const TeamScore = Teamscorr.filter(Teamel => {
    //       return Teamel.name == obj.Teamname
    //     })[0]
    //     console.log("asda : ", TeamScore)
    //     console.log("scorr : ", Teamscorr)
    //     if (TeamScore) {
    //       const TeamObj = {
    //         key: Teamscorr.length,
    //         id: TeamScore._id,
    //         name: TeamScore.name,
    //         createdAt: TeamScore.createdAt,
    //         total: TeamScore.total,
    //         rank: TeamScore.rank,
    //         // "Knife Ninja": TeamScore["Knife Ninja"],
    //         // "Monsters Up": TeamScore["Monsters Up"],
    //         // "Mr.Gun": TeamScore["Mr.Gun"],
    //         // "Flappy Dove": TeamScore["Flappy Dove"],
    //         // "TEN": TeamScore["TEN"],
    //         // "Quiz": TeamScore["Quiz"],
    //         // "Fortune Wheel": TeamScore["Fortune Wheel"],
    //         // "Cricket Challenge": TeamScore["Cricket Challenge"],
    //         // "Easter Memory": TeamScore["Easter Memory"],
    //         // "Car Rush": TeamScore["Car Rush"],
    //         // "Jigsaw": TeamScore["Jigsaw"],
    //         // "Pinball": TeamScore["Pinball"],
    //         // "Penalty Kicks": TeamScore["Penalty Kicks"],
    //         // "City Blocks": TeamScore["City Blocks"],
    //       }
    //       Alldata.helper.leaderboard.active.map((active, ind) => {
    //         TeamObj[active] = TeamScore[active]
    //       })
    //       Teamscorr.splice(0, 0, TeamObj)
    //     } else {
    //       const TeamObj = {
    //         key: Teamscorr.length,
    //         id: obj.id,
    //         name: obj.Teamname,
    //         createdAt: Date.now(),
    //         total: 0,
    //         rank: Teamscorr.length + 1,
    //       }
    //       Teamscorr.splice(0, 0, TeamObj)
    //     }
    //     // console.log(Teamscorr);
    //     setTeamScore(Teamscorr)
    //   })
    fetch(process.env.REACT_APP_ADMIN + "/getscores")
      .then(response => response.json())
      .then(async data => {
        // console.log("data: ",data);
        let activeGname=Alldata.helper.leaderboard.active
        await data.map((el, index) => {
          el.scores=el.scores.map((elem) => {if(activeGname.includes(elem.gname)){
            return elem
          }  })
          el.scores= el.scores.filter((elem)=>{if(elem){
            return elem
          }})
          let totalScore = el.scores.reduce(function (accumulator, sc) {
            return accumulator + sc.score
          }, 0)
          let item = {
            key: index,
            id: el.id,
            name: el.name,
            createdAt: el.createdAt,
            total: totalScore,
            rank: 0,
            // "Knife Ninja": el.scores.filter(el => { return el.gname == "Knife Ninja" })[0] ? el.scores.filter(el => { return el.gname == "Knife Ninja" })[0].score : 0,
            // "Monsters Up": el.scores.filter(el => { return el.gname == "Monsters Up" })[0] ? el.scores.filter(el => { return el.gname == "Monsters Up" })[0].score : 0,
            // "Mr.Gun": el.scores.filter(el => { return el.gname == "Mr.Gun" })[0] ? el.scores.filter(el => { return el.gname == "Mr.Gun" })[0].score : 0,
            // "Flappy Dove": el.scores.filter(el => { return el.gname == "Flappy Dove" })[0] ? el.scores.filter(el => { return el.gname == "Flappy Dove" })[0].score : 0,
            // "TEN": el.scores.filter(el => { return el.gname == "TEN" })[0] ? el.scores.filter(el => { return el.gname == "TEN" })[0].score : 0,
            // "Quiz": el.scores.filter(el => { return el.gname == "Quiz" })[0] ? el.scores.filter(el => { return el.gname == "Quiz" })[0].score : 0,
            // "Fortune Wheel": el.scores.filter(el => { return el.gname == "Fortune Wheel" })[0] ? el.scores.filter(el => { return el.gname == "Fortune Wheel" })[0].score : 0,
            // "Cricket Challenge": el.scores.filter(el => { return el.gname == "Cricket Challenge" })[0] ? el.scores.filter(el => { return el.gname == "Cricket Challenge" })[0].score : 0,
            // "Easter Memory": el.scores.filter(el => { return el.gname == "Easter Memory" })[0] ? el.scores.filter(el => { return el.gname == "Easter Memory" })[0].score : 0,
            // "Car Rush": el.scores.filter(el => { return el.gname == "Car Rush" })[0] ? el.scores.filter(el => { return el.gname == "Car Rush" })[0].score : 0,
            // "Jigsaw": el.scores.filter(el => { return el.gname == "Jigsaw" })[0] ? el.scores.filter(el => { return el.gname == "Jigsaw" })[0].score : 0,
            // "Pinball": el.scores.filter(el => { return el.gname == "Pinball" })[0] ? el.scores.filter(el => { return el.gname == "Pinball" })[0].score : 0,
            // "Penalty Kicks": el.scores.filter(el => { return el.gname == "Penalty Kicks" })[0] ? el.scores.filter(el => { return el.gname == "Penalty Kicks" })[0].score : 0,
            // "City Blocks": el.scores.filter(el => { return el.gname == "City Blocks" })[0] ? el.scores.filter(el => { return el.gname == "City Blocks" })[0].score : 0,
          }
          Alldata.helper.leaderboard.active.map((active, ind) => {
            item[active] = el.scores.filter(el => {
              return el.gname == item.active
            })
              ? el.scores.filter(el => {
                  return el.gname == active
                })[0]
                ? el.scores.filter(el => {
                    return el.gname == active
                  })[0].score
                : 0
              : 0
          })
          scorr.push(item)
          // console.log("scorr: ",scorr);
        })
        // sort by value
        scorr.sort(function (a, b) {
          return b.total - a.total
        })
        let i = 1
        scorr[i - 1] ? (scorr[i - 1].rank = 1) : null
        // console.log(scorr[i-1].total);
        while (i < scorr.length) {
          if (scorr[i - 1].total == scorr[i].total) {
            scorr[i].rank = scorr[i - 1].rank
          } else {
            scorr[i].rank = scorr[i - 1].rank + 1
          }
          i++
        }
        const myScore = scorr.filter(el => {
          return el.id == obj.Email
        })[0]
        if (myScore) {
          let msObj = {
            key: scorr.length,
            id: myScore.id,
            name: myScore.name,
            createdAt: myScore.createdAt,
            total: myScore.total,
            rank: myScore.rank,
            // "Knife Ninja": myScore["Knife Ninja"],
            // "Monsters Up": myScore["Monsters Up"],
            // "Mr.Gun": myScore["Mr.Gun"],
            // "Flappy Dove": myScore["Flappy Dove"],
            // "TEN": myScore["TEN"],
            // "Quiz": myScore["Quiz"],
            // "Fortune Wheel": myScore["Fortune Wheel"],
            // "Cricket Challenge": myScore["Cricket Challenge"],
            // "Easter Memory": myScore["Easter Memory"],
            // "Car Rush": myScore["Car Rush"],
            // "Jigsaw": myScore["Jigsaw"],
            // "Pinball": myScore["Pinball"],
            // "Penalty Kicks": myScore["Penalty Kicks"],
            // "City Blocks": myScore["City Blocks"],
          }
          Alldata.helper.leaderboard.active.map((active, ind) => {
            msObj[active] = myScore[active]
          })
          scorr.splice(0, 0, msObj)
        } else {
          const msObj = {
            key: scorr.length,
            id: obj.id,
            name: obj.Firstname,
            createdAt: Date.now(),
            total: 0,
            rank: scorr.length + 1,
          }
          scorr.splice(0, 0, msObj)
        }
        // console.log(scorr);
        setIndividualScore(scorr)
        document.querySelector("#customLoader").classList.add("loader--hide")
      })
  }, [])

  let columns = [
    // {
    //   dataField: 'id',
    //   text: 'Id',
    //   sort: false,
    // },
    // {
    //   dataField: 'key',
    //   text: 'Key',
    //   sort: false,
    // },
    {
      dataField: "rank",
      text: "Rank",
      sort: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "total",
      text: "Total",
      sort: false,
    },
  ]

  Alldata.helper.leaderboard.active.map((active, ind) => {
    columns.push({
      dataField: active,
      text: active,
      sort: false,
    })
  })

  const defaultSorted = [
    {
      dataField: "total",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 12,
    totalSize: IndividualScore.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "12", value: 12 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: IndividualScore.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "Individual",
                })}
                onClick={() => {
                  toggle1("Individual")
                  setactiveTabContent("1")
                }}
              >
                Individual Score
              </NavLink>
              <div id="1"></div>
            </NavItem>
            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "Team",
                })}
                onClick={() => {
                  toggle1("Team")
                  setactiveTabContent("2")
                }}
              >
                Team Score
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTabContent} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>
                        Leaderboard
                      </CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={IndividualScore}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={IndividualScore}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="position-relative" hidden>
                                      <ExportCSVButton
                                        {...toolkitProps.csvProps}
                                      >
                                        Export CSV!!
                                      </ExportCSVButton>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <center>
                      <svg id="customLoader" class="pl" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
	<defs>
		<linearGradient id="pl-grad" x1="0" y1="0" x2="0" y2="1">
			<stop offset="0%" stop-color="hsl(1,90%,55%)" />
			<stop offset="100%" stop-color="hsl(223,90%,55%)" />
		</linearGradient>
	</defs>
	<circle class="pl__ring" r="56" cx="64" cy="64" fill="none" stroke="hsla(1,100%,100%,1)" stroke-width="16" stroke-linecap="round" />
	<path class="pl__worm" d="M92,15.492S78.194,4.967,66.743,16.887c-17.231,17.938-28.26,96.974-28.26,96.974L119.85,59.892l-99-31.588,57.528,89.832L97.8,19.349,13.636,88.51l89.012,16.015S81.908,38.332,66.1,22.337C50.114,6.156,36,15.492,36,15.492a56,56,0,1,0,56,0Z" fill="none" stroke="url(#pl-grad)" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="44 1111" stroke-dashoffset="10" />
</svg></center>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>
                        Team Leaderboard
                      </CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={TeamScore}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={TeamScore}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Leaderboard
