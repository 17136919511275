import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

// Redux
import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
//custom
import {
  CardBody,
  Card,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Login = props => {
  const [ms, setMS] = useState(false)

  const [ActiveTab, setActiveTab] = useState("Team")
  const [activeTabContent, setactiveTabContent] = useState("2")
  const [alert, setAlert] = useState(true)
  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [modal, toggleMS] = useState(false)
  const togms = () => {
    toggleMS(!modal)
  }
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }
  const hideAlert = () => {
    setAlert(false)
  }
  const loader = document.querySelector(".loader")

  useEffect(() => {
    setTimeout(() => {
      loader.classList.add("loader--hide")
    }, 1000)
  }, [])

  return Alldata.loginData.holdingSite ? (
    <React.Fragment>
      <MetaTags>
        <title>Xceedance</title>
      </MetaTags>
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
          encodeURIComponent(Alldata.loginData.holdingImg) +
          "?alt=media"
        }
        style={{ position: "absolute", width: "100%" }}
      ></img>
      <Modal
        size="xl"
        isOpen={ms}
        toggle={() => {
          setMS(!ms)
        }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Photo Mosaic</h5>
          <button
            type="button"
            onClick={() => {
              setMS(!ms)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            allow="camera; microphone"
            title="test"
            className="embed-responsive-item mosaic"
            src={encodeURI("https://demo-mosaic.herokuapp.com/")}
          />
        </div>
      </Modal>
      <Container style={{ padding: 0, position: "relative" }} fluid>
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
            encodeURIComponent(Alldata.loginData.holdingImg) +
            "?alt=media"
          }
          style={{ position: "absolute", width: "100%" }}
        ></img>
        <div className="account-pages pt-sm-5">
          <center>
            <Card
              className="overflow-hidden cardLogin"
              style={{
                // boxShadow: "0px -5rem 2rem 0px rgb(18 38 63 / 3%)",
                boxShadow: "none",
              }}
            >
              <CardBody className="pt-0">
                <div className="p-2">
                  <Nav pills className="navtab-bg nav-justified">
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active:
                            ActiveTab === "Individual",
                        })}
                        onClick={() => {
                          toggle1("Individual");
                          setactiveTabContent("1")
                        }}
                      >

                        Login
                      </NavLink>
                      <div id="1">
                      </div>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: ActiveTab === "Team",
                        })}
                        onClick={() => {
                          toggle1("Team")
                          setactiveTabContent("2")
                        }}
                      >
                        Register
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {/* <div style={{fontSize:"0.7rem",textAlign:"left",color:"black",fontWeight:"bold"}} className="mt-1" >I consent and authorize ABB to the use of my data as part of an ABB initiative to celebrate the Technology Day virtually. Please refer to the ABB privacy policy to know more - <a href="https://new.abb.com/privacy-notice/virtual-events">https://new.abb.com/privacy-notice/virtual-events</a></div> */}
                  <TabContent
                    activeTab={activeTabContent}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          props.error == "Invalid Email!" ||
                          props.error ==
                            "Somethiing went wrong, Please try again!" ||
                          props.error ==
                            "Oops! Your password is incorrect. Please try again using the correct password." ||
                          props.error ==
                            "User Already registered , Please come back again on the event day to log in." ||
                          props.error == "User not Found!" ||
                          props.error ==
                            "User not Found! Please Register to login" ||
                          props.error ==
                            "Registration successful. Click a picture with the Break the Bias pose right away at the Photo Mosaic Feature!" ? (
                            props.error ==
                            "Registration successful. Click a picture with the Break the Bias pose right away at the Photo Mosaic Feature!" ? (
                              <SweetAlert
                                show={alert}
                                success
                                title="Registered Successfully"
                                onConfirm={hideAlert}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            ) : (
                              <SweetAlert
                                show={alert}
                                warning
                                // title="  Failed!"
                                onConfirm={hideAlert}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            )
                          ) : (
                            <SweetAlert
                              show={alert}
                              success
                              title="Login Success!"
                              onConfirm={hideAlert}
                              // timeout={4000}
                            >
                              {props.error}
                            </SweetAlert>
                          )
                        ) : null}
                        {/* <div className="newlog">
                      Login
                    </div> */}
                        <div className="mb-3">
                          <AvField
                            name="Firstname"
                            value=""
                            className="form-control newgrad"
                            placeholder="First Name *"
                            type="text"
                            required
                            style={{}}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="Lastname"
                            value=""
                            className="form-control newgrad"
                            placeholder="Last Name *"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Email"
                            value=""
                            className="form-control newgrad"
                            placeholder="Email ID*"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="customaction"
                            value="Login"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>
                        {/* <div className="mb-3">
                          <AvField
                            name="Password"
                            value=""
                            className="form-control newgrad"
                            placeholder="Password *"
                            type="password"
                            required
                          />
                        </div> */}

                        <div
                          className="mt-3 d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              backgroundColor: "#0b0b0b",
                              color: "white",
                              borderColor: "white",
                              width: "33%",
                            }}
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled
                          >
                            Log In
                          </button>
                        </div>
                        {/* <div style={{ fontSize: "0.7rem", textAlign: "left", color: "black", fontWeight: "bold" }} className="mt-1">Kindly Register for before login. </div> */}
                      </AvForm>
                    </TabPane>

                    <TabPane tabId="2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          props.error == "Invalid Email!" ||
                          props.error ==
                            "Somethiing went wrong, Please try again!" ||
                          props.error ==
                            "User Already registered , Please come back again on the event day to log in." ||
                          props.error ==
                            "Oops! Your password is incorrect. Please try again using the correct password." ||
                          props.error ==
                            "User Already registered , please login" ||
                          props.error == "User not Found!" ||
                          props.error ==
                            "User not Found! Please Register to login" ||
                          props.error ==
                            "Click a picture with the Break the Bias pose right away at the Photo Mosaic Feature!" ? (
                            props.error ==
                            "Click a picture with the Break the Bias pose right away at the Photo Mosaic Feature!" ? (
                              <SweetAlert
                                show={alert}
                                success
                                // showCancel
                                title="Registered Successfully"
                                cancelBtnBsStyle="danger"
                                confirmBtnText="Photo Mosaic"
                                onConfirm={() => {
                                  setMS(true)
                                  hideAlert(false)
                                }}
                                // onCancel={hideAlert}
                                // onClick={console.log("hi")}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            ) : props.error ==
                              "User Already registered , Please come back again on the event day to log in." ? (
                              <SweetAlert
                                show={alert}
                                success
                                // showCancel
                                title="Already Registered"
                                cancelBtnBsStyle="danger"
                                confirmBtnText="Photo Mosaic"
                                onConfirm={() => {
                                  setMS(true)
                                  hideAlert(false)
                                }}
                                // onCancel={hideAlert}
                                // timeout={4000}
                              >
                                {/* {props.error}  */}
                                Please come back again on the event day to log
                                in.Click a picture with the Break the Bias pose
                                right away at the Photo Mosaic Feature!
                              </SweetAlert>
                            ) : (
                              <SweetAlert
                                show={alert}
                                warning
                                // title="  Failed!"
                                onConfirm={hideAlert}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            )
                          ) : (
                            <SweetAlert
                              show={alert}
                              warning
                              title="Failed!"
                              onConfirm={hideAlert}
                              // timeout={4000}
                            >
                              {props.error}
                            </SweetAlert>
                          )
                        ) : null}
                        {/* <div className="newlog">
                      Login
                    </div> */}
                        <div className="mb-3">
                          <AvField
                            name="Firstname"
                            value=""
                            className="form-control newgrad"
                            placeholder="First Name *"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="Lastname"
                            value=""
                            className="form-control newgrad"
                            placeholder="Last Name *"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Email"
                            value=""
                            className="form-control newgrad"
                            placeholder="Email *"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="customaction"
                            value="Register"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>
                        {/* <div className="mb-3">
                          <AvField
                            name="Password"
                            value=""
                            className="form-control newgrad"
                            placeholder="Password *"
                            type="password"
                            required
                          />
                        </div> */}
                        <div
                          className="mt-3 d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              backgroundColor: "#414143",
                              color: "white",
                              borderColor: "white",
                              width: "33%",
                            }}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                        {/* <div style={{ fontSize: "0.7rem", textAlign: "left", color: "black", fontWeight: "bold" }} className="mt-1">Kindly Register for before login. </div> */}
                      </AvForm>
                    </TabPane>
                  </TabContent>
                  *Please use your official HPE ID to register
                </div>
              </CardBody>
            </Card>
          </center>
        </div>
      </Container>
    </React.Fragment>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
